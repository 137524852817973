/* eslint-disable max-lines */
import { InferGetStaticPropsType } from 'next';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import dynamic from 'next/dynamic';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import cn from 'classnames';
import { useProductQuery } from '@services/shopify';
import { useDebounce, useHasMounted, useIsMobile, useLocalStorage } from '@utils/hooks';
import {
	ALL_BASE_FRAME_HANDLES,
	BASE_FRAME_NAMES,
	COLOR_REFRESH_BASE_FRAMES,
	DEFAULT_BASE_FRAME_SHAPE,
	FRAME_COLOR_HANDLES,
	LARGE_WIDTH,
	FRAME_COLORS,
	LOCALE_DICT,
	NEW_COLORS,
	PRODUCT_TYPES,
	TOAST,
	TOP_FRAMES_PATH,
} from '@constants';
import { useToastContext } from '@context';
import useAlgoliaTracking from '@services/analytics/useAlgoliaTracking';
import { NormalizedVariant } from '@ts/product';
import { trackProductView } from '@services/analytics/trackers';
import {
	AboutValueSkeleton,
	Carousel,
	CarouselSkeleton,
	ErrorBoundary,
	ProductView,
	RecommendationCarouselSkeleton,
	SEO,
} from '@components';
import { isNullish } from '@utils/objects';
import { getStaticProps } from '@pages/top-frames/[collection]/[handle]';
import TopSidebar from '../TopSidebar';
import styles from './TopPDP.module.scss';

const AboutValueProp = dynamic(() => import('@components').then(mod => mod.AboutValueProp));
const LicensingInfoArea = dynamic(() => import('@components').then(mod => mod.LicensingInfoArea));
const RecommendationCarousel = dynamic(() => import('@components').then(mod => mod.RecommendationCarousel));
const YotpoReviews = dynamic(() => import('@components').then(mod => mod.YotpoReviews));

const TopPDP = ({
	baseFrameVariantsAvailable,
	frameSwatches,
	licensingData,
	product,
	productTags,
	video,
	yotpo,
	seoMetadata = {},
}: InferGetStaticPropsType<typeof getStaticProps> & { seoMetadata?: Parameters<typeof SEO>[0] }) => {
	const isTopPlpAltVideo = useFeatureIsOn('is-top-pdp-alt-video');
	const isStickySidebar = useFeatureIsOn('is-sticky-right-rail');
	const isMobile = useIsMobile({ maxWidth: LARGE_WIDTH });
	const { query, locale } = useRouter();
	const hasMounted = useHasMounted();
	const [activeFrame, setActiveFrame] = useLocalStorage<(typeof BASE_FRAME_NAMES)[number]>('activeFrame', 'Larkin');
	const [activeFrameColor, setActiveFrameColor] = useLocalStorage('activeFrameColor', 'Black');
	const { showToast } = useToastContext();
	const [frameShape, setFrameShape] = useState<(typeof BASE_FRAME_NAMES)[number]>(activeFrame);
	const [selectedImgIndex, setSelectedImgIndex] = useState(0);
	const sendViewEventToAlgolia = useAlgoliaTracking();
	const {
		name,
		type,
		metafields: { alternateVideo },
	} = product;

	useEffect(() => {
		const frame = frameShape as (typeof COLOR_REFRESH_BASE_FRAMES)[number];
		const selected = activeFrameColor as (typeof NEW_COLORS)[number];
		if (frameShape && !COLOR_REFRESH_BASE_FRAMES.includes(frame) && NEW_COLORS.includes(selected)) {
			setActiveFrameColor(FRAME_COLORS.BLACK);
		}
	}, [activeFrameColor, frameShape]);

	useEffect(() => {
		if (query.frameShape && query.frameShape !== frameShape) {
			setActiveFrame(query.frameShape as (typeof BASE_FRAME_NAMES)[number]);
			setFrameShape(query.frameShape as (typeof BASE_FRAME_NAMES)[number]);
		}
	}, [query.frameShape]);

	const topFrameOnModal = product.variantImages?.KIRBY;
	const isTopFrameBundle = type.includes('BUNDLE');
	const videoToUse = isTopPlpAltVideo ? (alternateVideo ?? video) : video;

	const activeBaseFrame =
		Object.entries(ALL_BASE_FRAME_HANDLES).find(([handle, frame]) => frame === frameShape && handle)[0] ?? null;

	const formattedColor = activeFrameColor.toLowerCase().replace(/\s+/g, '-');
	const activeBaseFrameHandle = activeBaseFrame
		? `${activeBaseFrame}-${formattedColor as keyof typeof FRAME_COLOR_HANDLES}`
		: null;

	const { data: variant, isLoading }: { data: NormalizedVariant; isLoading: boolean } = useProductQuery(
		product.handle,
		{
			selectedOptions: [{ name: 'Frame', value: frameShape }],
			includeSpecificFrameVariant: true,
			country: LOCALE_DICT[locale].countryCode,
		},
		{
			queryKey: 'top-frame-variants',
			queryRefreshVars: [frameShape, product.handle, locale],
		}
	);

	const { data: baseFrameData, isLoading: isBaseFrameVariantLoading } = useProductQuery(
		activeBaseFrameHandle,
		{ includeDescription: false, skipCollections: true, skipVariants: true },
		{
			queryKey: `base-frame-variant-${frameShape}`,
			queryRefreshVars: [activeBaseFrameHandle, frameShape],
		}
	);

	const topFrameImages = useMemo(() => {
		if (variant) {
			try {
				const varImgs = product.variantImages[variant.option.toUpperCase()];
				if (varImgs.length < 1) throw new Error();
				return varImgs;
			} catch (error) {
				// issue wih alt tag grouping
				const variantImage = [variant.image];
				return variantImage;
			}
		} else return [];
	}, [product.variantImages, variant]);

	const debouncedTrackProductView = useDebounce(trackProductView, 4000);
	const debouncedAlgoliaView = useDebounce(sendViewEventToAlgolia, 4000);
	useEffect(() => {
		if (!variant) return;
		debouncedAlgoliaView(variant.id);
		debouncedTrackProductView({
			variant,
			path: `${TOP_FRAMES_PATH}/${query.collection}`,
		});
	}, [variant]);

	useEffect(() => {
		if (!baseFrameVariantsAvailable.includes(frameShape)) {
			showToast(TOAST.VARIANT_UNAVAILABLE);
			const isAvailabeForDefault = baseFrameVariantsAvailable.includes(DEFAULT_BASE_FRAME_SHAPE);
			const defaultFrame = isAvailabeForDefault ? DEFAULT_BASE_FRAME_SHAPE : (baseFrameVariantsAvailable[0] ?? null);
			setFrameShape(defaultFrame);
			setActiveFrame(defaultFrame);
		}
	}, [baseFrameVariantsAvailable, frameShape]);

	const TopFrameDetails = () => {
		return (
			<ProductView.Details>
				{licensingData && !isNullish(licensingData) && <LicensingInfoArea {...licensingData} />}
				<ErrorBoundary skeleton={<AboutValueSkeleton />}>
					<AboutValueProp
						heading='Why You’ll Love Our Top Frames'
						type={PRODUCT_TYPES.TOP_FRAME}
						withOutMinWidth={isStickySidebar}
					/>
				</ErrorBoundary>
			</ProductView.Details>
		);
	};

	const Recommendations = () => {
		return (
			<ErrorBoundary skeleton={<RecommendationCarouselSkeleton />}>
				<RecommendationCarousel
					variant={!isLoading ? variant : null}
					selectedFrame={frameShape}
					headingText={`Similar to ${name}`}
					dataTags={{
						button: { 'data-add-to-cart-from-recommendation': true },
						zoom: {},
						favorite: { 'data-add-favorite-from-recommendation': true },
					}}
					withPadding={isStickySidebar}
				/>
			</ErrorBoundary>
		);
	};

	const TopContent = useCallback(
		({ isStickySidebar }: { isStickySidebar: boolean }) => {
			const content = (
				<>
					<TopFrameDetails />
					<Recommendations />
				</>
			);
			return isStickySidebar ? content : <ProductView.Recommendations>{content}</ProductView.Recommendations>;
		},
		[TopFrameDetails, Recommendations]
	);

	if (!hasMounted) return null;

	let secondaryImages;

	if (!isBaseFrameVariantLoading && !!baseFrameData) {
		secondaryImages = !!product.metafields.lifestyleImage
			? [...baseFrameData.variantImages[activeFrameColor], product.metafields.lifestyleImage]
			: baseFrameData.variantImages[activeFrameColor];
	}

	return (
		<>
			<ProductView.Root
				seo={{
					...seoMetadata,
					product: {
						...product,
						aggregateRating: yotpo,
						images: !isLoading ? [variant?.image] : [],
						suggestedGender: 'Unisex',
						variants: !isLoading ? [variant] : [],
					},
				}}
				isStickySidebar={isStickySidebar}
			>
				<ProductView.Images>
					<ErrorBoundary skeleton={<CarouselSkeleton />}>
						<Carousel
							className={cn({
								[styles['carousel']]: !isStickySidebar,
							})}
							images={topFrameImages}
							isImageLoading={isLoading}
							isSecondaryImageLoading={isBaseFrameVariantLoading}
							name={name}
							secondary={!isTopFrameBundle && secondaryImages}
							selectedImgIndex={selectedImgIndex}
							setSelectedImgIndex={setSelectedImgIndex}
							type={type}
							topFrameOnModal={topFrameOnModal}
							video={videoToUse}
							variant={!isLoading ? variant : null}
							isSticky={!isStickySidebar}
						/>
					</ErrorBoundary>
					{isStickySidebar && !isMobile && <TopContent isStickySidebar={isStickySidebar} />}
				</ProductView.Images>
				<TopSidebar
					product={{ ...product, sellingPlans: variant?.sellingPlans || product.sellingPlans }}
					productTags={productTags}
					yotpo={yotpo}
					setFrameShape={setFrameShape}
					baseFrameVariantsAvailable={baseFrameVariantsAvailable}
					frameSwatches={frameSwatches}
					frameShape={frameShape}
					activeFrameColor={activeFrameColor}
					handleActiveFrameColorChange={setActiveFrameColor}
					isStickySidebar={isStickySidebar}
				/>
				{(!isStickySidebar || isMobile) && <TopContent isStickySidebar={isStickySidebar} />}
				<ErrorBoundary>
					<ProductView.Reviews>
						<YotpoReviews product={product} />
					</ProductView.Reviews>
				</ErrorBoundary>
			</ProductView.Root>
		</>
	);
};

export default TopPDP;
